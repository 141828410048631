/* eslint-disable complexity */
export function getInputValueForConditionalLogic(inputValue) {
    let value = inputValue;

    // Ensure input starts with "if "
    value =
        inputValue?.toLowerCase()?.includes("then") ||
        inputValue?.toLowerCase()?.includes("or") ||
        inputValue?.toLowerCase()?.includes("and")
            ? inputValue?.toLowerCase()?.startsWith("if ")
                ? inputValue
                : `if ${inputValue}`
            : inputValue;

    // Replace "else   (" with "else if (", but not if it's "else (STATUS"
    value = value
        ?.replace(/else\s+\((?!STATUS)/g, "else if (") // Prevent replacement for "else (STATUS"
        ?.replace(/ELSE\s+\(/g, "else if (") // Handle uppercase ELSE
        ?.replace("IF", "if")
        ?.replace("THEN", "then")
        ?.replace("ELSE", "else")
        ?.replace("OR", "or")
        ?.replace("AND", "and");

    return value;
}
