/* eslint-disable complexity */
export function extractAndValidate(expression) {
    let value1 = null;
    let value2 = null;
    if(expression.includes('===')) {
        value1 = expression.split('===')[0];
        value2 = expression.split('===')[1];
    } else if(expression.includes('<')) { 
        value1 = expression.split('<')[0];
        value2 = expression.split('<')[1];
    } else if(expression.includes('>')) {
        value1 = expression.split('>')[0];
        value2 = expression.split('>')[1];
    }
    else if(expression.includes('!=')) {
        value1 = expression.split('!=')[0];
        value2 = expression.split('!=')[1];
    }else if(expression.includes('<=') && expression.includes('>=')) {
        value1 = expression.split('<=')[0];
        value2 = expression.split('<=')[1];
    } else if(expression.includes('<=')) {
        value1 = expression.split('<=')[0];
        value2 = expression.split('<=')[1];
    } else if(expression.includes('>=')) {
        value1 = expression.split('>=')[0];
        value2 = expression.split('>=')[1];
    }
    
    switch  (true) { 
    case expression.includes('AddDays'):
        value1 = 'AddDays'
        value2 = value2.split('"')[1]
        break;
    case expression.includes('SubtractDays'):
        value1 = 'SubtractDays'
        value2 = value2.split('"')[1]
        break;
    case expression.includes('getFullYear'):
        value1 = 'getFullYear'
        break;
    case expression.includes('getDate'):
        value1 = 'getDate'
        break;
    case expression.includes('getDay'):
        value2 = value2.split('"')[1]
        value1 = 'getDay'
        break;
    case expression.includes('getHours'):
        value1 = 'getHours'
        break;
    case expression.includes('getMinutes'):
        value1 = 'getMinutes'
        break;
    case expression.includes('getSeconds'):
        value1 = 'getSeconds'
        break;
    case expression.includes('getTime'):
        value1 = 'getTime'
        break;
    }
    return validateDateTime(value2.trim(), value1);
}
function validateDate(input) {
    // Check if input follows the format DD/MM/YYYY
    const datePattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    if (!datePattern.test(input)) {
        return "Invalid date format. Use DD/MM/YYYY.";
    }

    const parts = input.split('/');
    
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // Check for valid day (1 to 31)
    if (isNaN(day) || day < 1 || day > 31) {
        return "Invalid day format. Enter a number between 1 and 31.";
    }
    
    // Check for valid month (1 to 12)
    if (isNaN(month) || month < 1 || month > 12) {
        return "Invalid month. Enter a number between 1 and 12.";
    }
    
    // Check for valid year (4-digit number)
    if (isNaN(year) || year < 1000 || year > 9999) {
        return "Invalid year. Year should be a four-digit number.";
    }

    // Function to check if the year is a leap year
    function isLeapYear(year) {
        return (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
    }

    // Number of days in each month (for non-leap years)
    const daysInMonth = {
        1: 31, 2: 28, 3: 31, 4: 30, 5: 31, 6: 30,
        7: 31, 8: 31, 9: 30, 10: 31, 11: 30, 12: 31
    };

    // If it's a leap year, set February to 29 days
    if (isLeapYear(year)) {
        daysInMonth[2] = 29;
    }

    // Check if the day is valid for the given month
    if (day > daysInMonth[month]) {
        return `Invalid day for the selected month. ${month} only has ${daysInMonth[month]} days.`;
    }

    return "";
}


function validateDateTime(input, type) {
    switch (type) {
    case "AddDays":
    case "SubtractDays":
        return validateDate(input);
    case "getFullYear":
        if (input.includes('"') || input < 1000 || input > 9999) {
            return "Invalid year. Year should be a four-digit number."; 
        }
        break;

    case "getDate":
        if (input.includes('"') || input < 1 || input > 31) {
            return "Invalid date. Enter a number between 1 and 31."; 
        } 
        break;
    case "getMonth":
        if (input.includes('"') || input < 1 || input > 12) {
            return "Invalid month. Enter a number between 1 and 12."; 
        } 
        break;

    case "getDay":
        const validDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        if (!validDays.includes(input)) {
            return `Invalid day. Expected values: ${validDays.join(", ")} (case-sensitive).`; 
        }
        break;

    case "getHours":
        if (!Number.isInteger(input) || input < 0 || input > 24) {
            return "Invalid hours. Enter a number between 0 and 24."; 
        }
        break;

    case "getMinutes":
        if (!Number.isInteger(input) || input < 0 || input > 59) {
            return "Invalid minutes. Enter a number between 0 and 59."; 
        }
        break;

    case "getSeconds":
        if (!Number.isInteger(input) || input < 0 || input > 59) {
            return "Invalid seconds. Enter a number between 0 and 59."; 
        }
        break;
    }
    return ""; 
}
